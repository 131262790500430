import { React, useState } from "react";
import Logo1 from "../img/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGoogle, faYelp , faInstagram} from "@fortawesome/free-brands-svg-icons";


function Navbar(props) {
    const [Scroll, SetScroll] = useState(false);

    const Change_color = () => {
        if (window.scrollY >= 10) {
            SetScroll(true);
        }
        else { SetScroll(false); }
    };
    window.addEventListener('scroll', Change_color)
    const Icon_Style = { fontSize: '22', };
    return (
        <div className="">
            <nav className={Scroll ? "bg-black bg-opacity-20 shadow-lg px-4 transition ease-out duration-200  delay-100 flex font-header-font justify-center lg:justify-around w-full fixed top-0 left-0 right-0 z-10 " :
                "flex text-white justify-center lg:justify-around bg-none px-4 font-header-font w-screen fixed py-4 top-0 left-0 right-0 z-10"}>
                <div className="flex items-center cursor-pointer group">
                    <img src={Logo1} alt="" className={Scroll ? "w-24 group-hover:opacity-70": "w-24 group-hover:opacity-70"} />
                </div>
                <div className={Scroll ? "lg:flex text-md hidden items-center space-x-20 text-xl capitalize text-white" :
                    "lg:flex text-md hidden items-center space-x-20 text-xl capitalize text-white"}>
                    <a onClick={() => props.handleTabClick("Home")} className={`${props.isActive("Home")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/">
                        Home
                    </a>
                </div>
        
                <div className={Scroll ? "lg:hidden text-3xl cursor-pointer flex items-center text-black space-x-4" :
                    "lg:hidden space-x-4 blkock text-3xl cursor-pointer flex items-center"}>
                </div>
                
                <div className={Scroll ? "hidden lg:flex items-center space-x-12 text-white" :
                    "hidden lg:flex items-center  space-x-12 text-white"}>
                    
                    <a className="cursor-pointer flex" target="__blank" rel="noreferrer" href="https://maps.app.goo.gl/NRvhLSGgFTFyAkk89">
                        <FontAwesomeIcon className="hover:text-green-300" style={Icon_Style} icon={faGoogle}></FontAwesomeIcon>
                    </a>
                    <a className="cursor-pointer flex" target="__blank" rel="noreferrer" href="https://www.yelp.com/biz/cre-asian-flavors-of-vietnam-brentwood">
                        <FontAwesomeIcon className="hover:text-red-400 transition ease-in-out delay-150 duration-200" style={Icon_Style} icon={faYelp}></FontAwesomeIcon>
                    </a>
                    <a className="cursor-pointer flex" target="__blank" rel="noreferrer" href="https://www.instagram.com/creasian.lonetree?utm_source=qr">
                        <FontAwesomeIcon className="hover:text-rose-200 transition ease-in-out delay-150 duration-200" style={Icon_Style} icon={faInstagram}></FontAwesomeIcon>
                    </a>
                </div>
            </nav>
        </div>
    );
};


export default Navbar;