import React , {useEffect} from "react";
import {Carousel , initTE} from "tw-elements"

function CarouselHome (){
    useEffect (() => {
        initTE({Carousel});
    },[]);
    
    return (
        <>
            <div id="CarouselHome" className="relative h-full shadow-lg flex" data-te-interval="10" data-te-carousel-init data-te-ride="carousel">
                <div className="relative overflow-hidden w-full h-full after:clear-both after:block after:content-['']">
                    <div className="relative float-left w-full h-full !transform-none opacity-0 transition-opacity  ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item data-te-carousel-active>
                        <div className="  bg-bg1 w-full h-full relative bg-center bg-no-repeat bg-cover">
                            <div className="bg-black w-full h-full flex items-center bg-opacity-20  justify-center tracking-wide text-white">
                                <button className=" px-2 border-main hover:shadow-2xl transition ease-in-out duration-200 delay-100 group hover:border-main-color cursor-pointer bg-white hover:bg-main-color h-[100px] w-[100px] border-[1px]">
                                    <span className=" transition ease-in-out duration-200 delay-100 text-xl text-main-color group-hover:text-white">
                                        View Menu
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-full float-left -mr-[100%] hidden !transform-none opacity-0 transition-opacity  ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item>
                        <div className="  bg-bg2 relative bg-center w-full h-full bg-no-repeat bg-cover">
                            <div className="flex !font-serif bg-black w-full h-full items-center bg-opacity-20 justify-center tracking-wide text-white">
                                <button className=" px-2 border-main hover:shadow-2xl transition ease-in-out duration-200 delay-100 group hover:border-main-color cursor-pointer bg-white hover:bg-main-color h-[100px] w-[100px] border-[1px]">
                                    <span className=" transition ease-in-out duration-200 delay-100 text-xl text-main-color group-hover:text-white">
                                        View Menu
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-full float-left -mr-[100%] hidden !transform-none opacity-0 transition-opacity  ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item>
                        <div className="  bg-bg3 relative w-full h-full bg-center bg-no-repeat bg-cover ">
                            <div className="flex !font-serif w-full h-full bg-black items-center bg-opacity-20 justify-center tracking-wide text-white">
                                <button className=" px-2 border-main hover:shadow-2xl transition ease-in-out duration-200 delay-100 group hover:border-main-color cursor-pointer bg-white hover:bg-main-color h-[100px] w-[100px] border-[1px]">
                                    <span className=" transition ease-in-out duration-200 delay-100 text-xl text-main-color group-hover:text-white">
                                        View Menu
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-full float-left -mr-[100%] hidden !transform-none opacity-0 transition-opacity  ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item>
                        <div className="  bg-bg4 relative w-full h-full bg-center bg-no-repeat bg-cover ">
                            <div className="flex !font-serif bg-black items-center bg-opacity-20 w-full h-full justify-center tracking-wide text-white">
                                <button className=" px-2 border-main hover:shadow-2xl transition ease-in-out duration-200 delay-100 group hover:border-main-color cursor-pointer bg-white hover:bg-main-color h-[100px] w-[100px] border-[1px]">
                                    <span className=" transition ease-in-out duration-200 delay-100 text-xl text-main-color group-hover:text-white">
                                        View Menu
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="relative float-left w-full h-full -mr-[100%] hidden !transform-none opacity-0 transition-opacity  ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item>
                        <div className="  bg-bg5 relative w-full h-full bg-center bg-no-repeat bg-cover ">
                            <div className="flex !font-serif bg-black items-center bg-opacity-20  w-full h-full justify-center tracking-wide text-white">
                                <button className=" px-2 border-main hover:shadow-2xl transition ease-in-out duration-200 delay-100 group hover:border-main-color cursor-pointer bg-white hover:bg-main-color h-[100px] w-[100px] border-[1px]">
                                    <span className=" transition ease-in-out duration-200 delay-100 text-xl text-main-color group-hover:text-white">
                                        View Menu
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CarouselHome;