import React, {useState} from "react";
import {Fade, Slide} from "react-awesome-reveal";


import { RxDoubleArrowDown, RxArrowRight} from "react-icons/rx";

import videoIntro from "../img/bgbig.mp4";
import videoIntro1 from "../img/bgvideo.mp4";
import CarouselHome from "../Components/Carousel";



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {faFacebookF, faInstagram, faYelp } from "@fortawesome/free-brands-svg-icons";


export default function Home(){

	const [activeLocation, setActiveLocation] = useState("Campbell");
	const[isPath, setPath] = useState("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202978.50361975122!2d-122.163974971875!3d37.35341850152151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808e3548ce9858cb%3A0x5121ef5a45a158de!2sLequy!5e0!3m2!1sen!2sus!4v1731225280560!5m2!1sen!2sus");

	const handleLocationClick = (tabName, path) => {
		setActiveLocation(tabName);
		setPath(path);
	};

	const isActiveLocation = (tabName) => {
		return activeLocation === tabName ? " text-white" : "text-white/40";
	};

    
	function scrollSec(){
        const home1 = document.getElementById('Home1');
        home1?.scrollIntoView({
            behavior:"smooth"
        });
    };

	const Section2 = [
		{
			id:1,
			name: 'Campbell',
			path: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202978.50361975122!2d-122.163974971875!3d37.35341850152151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808e3548ce9858cb%3A0x5121ef5a45a158de!2sLequy!5e0!3m2!1sen!2sus!4v1731225280560!5m2!1sen!2sus',
			img: '',
			phone: '(408) 871-7171',
			address: '104 San Tomas Aquino Rd, Campbell, CA 95008',
			yelp: 'https://www.yelp.com/biz/lequy-campbell'
		},
		{
			id:2,
			name: 'Palo Alto',
			path: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.75518158519!2d-122.1104142!3d37.41926109999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fbb454e43675f%3A0xf968a84b5c7d8e16!2sLeQuy!5e0!3m2!1sen!2sus!4v1731226909860!5m2!1sen!2sus',
			img: '',
			phone: '(650) 561-3406',
			address: '3906 Middlefield Rd, Palo Alto, CA 94306',
			yelp: 'https://www.yelp.com/biz/lequy-palo-alto?start=40'
		},
		{
			id:3,
			name: 'San Francisco',
			path: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d403797.67529678566!2d-123.01099777221684!3d37.750900927687255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f7f004ea20315%3A0x7f6b49c190cc93ce!2sLeQuy!5e0!3m2!1sen!2sus!4v1731226987346!5m2!1sen!2sus',
			img: '',
			phone: '(415) 829-3442',
			address: '1320 Castro St, San Francisco, CA 94114',
			yelp: 'https://www.yelp.com/biz/lequy-vietnamese-restaurant-san-francisco'
		}
	]


    return (

		<main>
			<section className="">
				<video playsInline = "true" src={videoIntro1} loop="true" muted="true" autoPlay="autoplay" className='object-cover block lg:hidden absolute h-screen w-screen -z-10 top-0 left-0'></video>
				<video playsInline = "true" src={videoIntro} loop="true" muted="true" autoPlay="autoplay" className='object-cover hidden lg:block absolute h-screen w-screen -z-10 top-0 left-0'></video>
				<div className="py-5 flex flex-col bg-black bg-opacity-20 lg:bg-opacity-55 h-screen w-screen justify-between tracking-wide text-white">
					<div className=" text-white/50 font-Playfair tracking-tight h-full text-6xl capitalize space-y-12 px-12 hidden lg:flex flex-col justify-end">
						<div className=" flex flex-row w-1/3 space-x-3 cursor-pointer group">
							<span className=" group-hover:text-white">Campbell</span>
							<RxArrowRight className="ease-in-out delay-100 duration-150 group-hover:text-white group-hover:-translate-y-2 group-hover:opacity-100 opacity-0" />
						</div>
						<div className=" flex flex-row w-1/3 space-x-3 cursor-pointer group">
							<span className=" group-hover:text-white">Palo Alto</span>
							<RxArrowRight className="ease-in-out delay-100 duration-150 group-hover:text-white group-hover:-translate-y-2 group-hover:opacity-100 opacity-0" />
						</div>
						<div className=" flex flex-row w-1/3 space-x-3 cursor-pointer group">
							<span className=" group-hover:text-white">San Francisco</span>
							<RxArrowRight className="ease-in-out delay-100 duration-150 group-hover:text-white group-hover:-translate-y-2 group-hover:opacity-100 opacity-0" />
						</div>
					</div>
					<div className="p-4 hidden lg:flex justify-center items-center">
						<button onClick={() => scrollSec()} className="group ">
							<RxDoubleArrowDown className="ease-in-out delay-100 duration-150 group-hover:-translate-y-2 group-hover:text-main-color text-5xl" />
						</button>
					</div>
				</div>
			</section>
			
			<section id="Home1" className=" bg-bg-color">
				<div className=" p-5 pt-8 pb-8 lg:pt-24 lg:pb-24">
					<Fade>
						<div className="mx-auto space-y-4 lg:space-y-12 max-w-screen-xl">
							<div className=" flex items-center space-y-4 lg:space-y-10 flex-col">
								<div className="tracking-wider space-y-2">
									<div className=" border-[1px] border-main-color w-20"></div>
									<h2 className=" text-main-color font-Playfair text-3xl text-center lg:text-7xl">
										"Tradition" in Elegance
									</h2>
								</div>
								<div className=" text-center justify-center text-txt-color lg:text-xl tracking-wide">
									<h2>
										Welcome to Le Quy, where we invite you to experience the rich traditions of Vietnamese cuisine. 
										Our restaurant celebrates the essence of Vietnam, blending authentic flavors with modern presentation 
										to bring you an unforgettable dining experience. Each dish at Le Quy is crafted with fresh, 
										high-quality ingredients, paying homage to time-honored recipes passed down through generations.
									</h2>
								</div>
							</div>
						</div>
					</Fade>
				</div>
			</section>

			<section>
				<div className=" grid lg:grid-cols-2">
					<div className=" bg-main-color">
						<div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
							<div className=" mx-auto max-w-2xl space-y-4 lg:space-y-12">
								<div className="flex items-center space-y-5 lg:space-y-10 flex-col">
									<div className="tracking-wider space-y-2">
										<div className=" border-[1px] border-main-color w-20"></div>
										<h2 className=" text-white font-Playfair text-[30px] text-center lg:text-[40px]">
											Our Location
										</h2>
									</div>
									<div className=" flex w-full justify-evenly lg:justify-between">
										{Section2.map((data) => (
											<div onClick={() => handleLocationClick(data.name, data.path)} className=" cursor-pointer flex flex-col items-center justify-center space-y-2" key={data.id}>
												<div className={activeLocation === data.name? "border-[1px] bg-white border-white w-12 lg:w-16" : "hidden"}></div>
												<div>
													<h2 className={`${isActiveLocation(data.name)} text-xl font-serif lg:text-3xl`}>{data.name}</h2>
												</div>
											</div>
										))}
									</div>
									<div className=" w-full lg:h-[400px]">
										<iframe title={activeLocation} className=" w-full h-[300px] lg:h-full" id="" src={isPath} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className=" bg-bg-color h-[400px] lg:h-full">
						<div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24 h-full">
							<div className=" mx-auto max-w-2xl h-full">
								<div className="flex h-full space-y-5 lg:space-y-10 flex-col">
									<div className="tracking-wider space-y-2">
										<h2 className=" text-main-color font-Playfair text-[30px] text-center lg:text-[40px]">
											Our Menu
										</h2>
									</div>
									<div className="h-full">
										<CarouselHome />
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</section>

			<section className="bg-bg6 lg:bg-fixed bg-center bg-cover h-[400px] lg:h-[500px]">
				<div className=" bg-black h-[400px] lg:h-[500px] bg-opacity-50">
					<div className=" p-5 pt-8 pb-8 h-full ">
						<div className=" mx-2 lg:mx-12 flex h-full flex-col justify-center space-y-4 lg:space-y-7">
							<Slide direction="tl" delay={10}>

								<div className="">
									<h2 className=" text-white font-Playfair font-bold text-4xl lg:text-6xl">
										"Savor" the Soul of Vietnam
									</h2>
								</div>
								<div className="lg:max-w-2xl lg:mx-2 text-lg lg:text-2xl tracking-wide">
									<h2 className=" text-white/90">
										Reflects our dedication to authentic Vietnamese flavors and culinary tradition. Each dish offers a taste of Vietnam’s heritage, crafted with passion and elegance for an unforgettable dining experience.
									</h2>
								</div>
							</Slide>
						</div>
					</div>
				</div>
			</section>

			<section className="bg-bg7 bg-no-repeat bg-center bg-cover">
				<div className=" bg-black bg-opacity-70">
					<div className=" p-5 pt-12 pb-8 lg:pt-24 lg:pb-24">
						<div className="mx-auto space-y-4 lg:space-y-12 max-w-screen-xl">
							<div className=" flex space-y-4 lg:space-y-12 flex-col">
								<div className="tracking-wider space-y-2">
									<h2 className=" text-white font-Playfair font-bold uppercase text-2xl lg:text-5xl">
										Place Your Order
									</h2>
								</div>
								{Section2.map((data) => (
									<Slide delay={10}>
										<div key={data.id} className="pb-4 grid lg:grid-cols-3 lg:gap-0 gap-6 text-white">
											<div className=" flex flex-col space-y-2 lg:space-y-5">
												<div className=" flex flex-col">
													<h2 className="font-Playfair text-3xl lg:text-5xl">{data.name}</h2>
												</div>
												<div className=" flex flex-col space-y-4">
													<div className=" flex items-center space-x-2">
														<FontAwesomeIcon icon={faLocationDot} className=" lg:text-2xl text-white/70" />
														<div className="w-3/4">
															<span className=" lg:text-lg text-white/90">{data.address}</span>
														</div>
													</div>
													<div className=" flex items-center space-x-2">
														<FontAwesomeIcon icon={faPhone} className=" lg:text-2xl text-white/70" />
														<div onClick={() => window.location.href = `tel:${data.phone}`}  className="w-4/5 cursor-pointer group">
															<span className="lg:text-lg group-hover:text-blue-200 text-white/90">{data.phone}</span>
														</div>
													</div>
												</div>
											</div>
											<div>
												<div className=" flex space-x-2 lg:space-x-3">
													<FontAwesomeIcon icon={faClock} className=" lg:text-2xl text-white/70" />
													<div className=" flex flex-col text-white/90 lg:text-lg space-y-2">
														{data.id === 1 && (
															<>
																<div className="flex">
																	<span>Monday - Sunday: 10:00 AM - 9:00 PM</span>
																</div>
																<div className="flex">
																	<span>Wednesday: Closed</span>
																</div>
															</>
														)}
														{data.id === 3 && (
															<>
																<div className="flex">
																	<span>Monday - Sunday: 11:00 AM - 9:00 PM</span>
																</div>
																<div className="flex">
																	<span>Tuesday: Closed</span>
																</div>
															</>
														)}
														{data.id === 2 && (
															<>
																<div className="flex flex-col">
																	<span>Monday - Thursday: 10:00 AM - 3:00 PM </span>
																	<span>4:00 PM - 9:00 PM</span>
																</div>
																<div className="flex">
																	<span>Friday - Sunday: 10:00 AM - 9:00 PM</span>
																</div>
																<div className="flex">
																	<span>Wednesday: Closed</span>
																</div>
															</>
														)}
													</div>
												</div>
											</div>
											<div className="flex justify-center lg:justify-end lg:items-end">
												<div className=" flex gap-12 items-center">
													<div className=" flex gap-8">
														<FontAwesomeIcon onClick={() => window.location.href = 'https://www.facebook.com/lequyrestaurant/'} className=" cursor-pointer hover:text-sky-400 text-2xl" icon={faFacebookF} />
														<FontAwesomeIcon onClick={() => window.location.href = 'https://www.instagram.com/lequy.restaurant/'} className=" text-2xl cursor-pointer hover:text-pink-400" icon={faInstagram} />
														<FontAwesomeIcon onClick={() => window.location.href = data.yelp} className=" text-2xl cursor-pointer hover:text-rose-400" icon={faYelp} />
													</div>
													<div>
														<button onClick={() => window.location.href = `tel:${data.phone}`} className="hover:shadow-2xl hover:-translate-y-2 transition ease-in-out duration-200 delay-100 group rounded-xl hover:border-main-color cursor-pointer border-2 border-white hover:bg-main-color px-4 py-2 lg:px-12 lg:py-4">
															<span className=" transition ease-in-out duration-200 delay-100 lg:text-xl text-white group-hover:text-white">
																Order Now
															</span>
														</button>
													</div>
												</div>
											</div>
										</div>
										<hr></hr>
									</Slide>
									
								))}
							</div>
						</div>
					</div>
				</div>
			</section>

		</main>

    );

};